/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: 'Flaticon';
	src: url('../../assets/fonts/Flaticon.eot');
	src:
		url('../../assets/fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
		url('../../assets/fonts/Flaticon.woff2') format('woff2'),
		url('../../assets/fonts/Flaticon.woff') format('woff'),
		url('../../assets/fonts/Flaticon.ttf') format('truetype'),
		url('../../assets/fonts/Flaticon.svg#Flaticon') format('svg');
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	@font-face {
		font-family: 'Flaticon';
		src: url('../../assets/fonts/Flaticon.svg#Flaticon') format('svg');
	}
}
[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-crosshair:before {
	content: '\f100';
}
.flaticon-search:before {
	content: '\f101';
}
.flaticon-pin:before {
	content: '\f102';
}
.flaticon-category:before {
	content: '\f103';
}
.flaticon-more:before {
	content: '\f104';
}
.flaticon-user:before {
	content: '\f105';
}
.flaticon-down-arrow:before {
	content: '\f106';
}
.flaticon-shop:before {
	content: '\f107';
}
.flaticon-commerce:before {
	content: '\f108';
}
.flaticon-project:before {
	content: '\f109';
}
.flaticon-growth:before {
	content: '\f10a';
}
.flaticon-furniture-and-household:before {
	content: '\f10b';
}
.flaticon-save:before {
	content: '\f10c';
}
.flaticon-heart:before {
	content: '\f10d';
}
.flaticon-bed:before {
	content: '\f10e';
}
.flaticon-cooking:before {
	content: '\f10f';
}
.flaticon-more-1:before {
	content: '\f110';
}
.flaticon-placeholder:before {
	content: '\f111';
}
.flaticon-support:before {
	content: '\f112';
}
.flaticon-support-1:before {
	content: '\f113';
}
.flaticon-tick:before {
	content: '\f114';
}
.flaticon-zoom:before {
	content: '\f115';
}
.flaticon-shopping-bags:before {
	content: '\f116';
}
.flaticon-clock:before {
	content: '\f117';
}
.flaticon-share:before {
	content: '\f118';
}
.flaticon-www:before {
	content: '\f119';
}
.flaticon-compass:before {
	content: '\f11a';
}
.flaticon-direction:before {
	content: '\f11b';
}
.flaticon-right-chevron:before {
	content: '\f11c';
}
.flaticon-left-chevron:before {
	content: '\f11d';
}
.flaticon-hotel:before {
	content: '\f11e';
}
.flaticon-exercise:before {
	content: '\f11f';
}
.flaticon-cleansing:before {
	content: '\f120';
}
.flaticon-calendar:before {
	content: '\f121';
}
.flaticon-heart-1:before {
	content: '\f122';
}
.flaticon-airport:before {
	content: '\f123';
}
.flaticon-car-insurance:before {
	content: '\f124';
}
.flaticon-attorney:before {
	content: '\f125';
}
.flaticon-plumber:before {
	content: '\f126';
}
.flaticon-right-arrow:before {
	content: '\f127';
}
.flaticon-quotation:before {
	content: '\f128';
}
